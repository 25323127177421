/* eslint-disable react/prop-types */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Stack } from "@mui/material";
// Material Dashboard 2 PRO React components
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";

function Header({ id, invoiceNumber, createdDt, status, color }) {
  return (
    <MDBox display="flex" justifyContent="space-between" alignItems="top">
      <MDBox>
        <MDTypography variant="h6" fontWeight="medium">
          Request Design Order Details
        </MDTypography>
        <MDTypography component="p" variant="button" color="text">
          Order no. <b>{id}</b> from <b>{moment(createdDt).format("DD MMM YYYY")}</b>
        </MDTypography>
        <MDTypography component="p" variant="button" fontWeight="regular" color="text">
          Invoice: <b>{invoiceNumber}</b>
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDTypography variant="h6" fontWeight="medium">
          Status
        </MDTypography>
        <MDBadge variant="gradient" color={color} size="xs" badgeContent={status} container />
      </MDBox>
    </MDBox>
  );
}

export default Header;
