/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
// @mui material components
import { Paper, Grid, Divider } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";

// RequestDetails page components
import Header from "./components/Header";
import Overview from "./components/Overview";
import SubmitForm from "./components/SubmitForm";
import Summary from "./components/Summary";
import UploadForm from "./components/UploadForm";
import EditorForm from "./components/EditorForm";

function RequestDetails({ data, handleSubmit }) {
  const [open, setOpen] = useState(false);
  // Modal toggle handlers
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    id,
    invoiceNumber,
    status,
    amount,
    daysOfWork,
    complexity,
    discount,
    createdDt,
    reference_1_image,
    reference_2_image,
    result_front_image,
    result_back_image,
    reference_cr_1_image,
    reference_cr_2_image,
    result_cr_front_image,
    result_cr_back_image,
    description,
    requestDetails,
    user,
  } = data;

  const Status = {
    PENDING: "Pending",
    SUBMIT: "Submit",
    WAITING_PAYMENT: "Waiting Payment",
    EXPIRED: "Expired",
    PAID: "Paid",
    EDITOR: "Editor",
    CONFIRM: "Confirm",
    CR: "CR",
    DECLINE: "Decline",
    ACCEPT: "Accept",
    FINISHED: "Finished",
  };

  const Part = {
    FRONT: "Front",
    BACK: "Back",
    BOTH: "Both",
  };

  const getColor = () => {
    let color;
    switch (status) {
      case Status.FINISHED:
        color = "success";
        break;
      case Status.EXPIRED || Status.DECLINE:
        color = "error";
        break;
      case Status.PENDING || Status.WAITING_PAYMENT:
        color = "warning";
        break;
      default:
        color = "info";
        break;
    }

    return color;
  };

  const uploadedCR = () => result_cr_front_image || result_cr_back_image;

  const getFront = () => requestDetails.find((e) => e.part === Part.FRONT || e.part === Part.BOTH);
  const getBack = () => requestDetails.find((e) => e.part === Part.BACK || e.part === Part.BOTH);
  return (
    <Paper elevation={3} style={{ padding: "20px", width: "100%", margin: "auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header
            id={id}
            invoiceNumber={invoiceNumber}
            createdDt={createdDt}
            status={status}
            color={getColor()}
          />
        </Grid>
        {status === Status.SUBMIT && (
          <Grid item xs={12}>
            <Divider />
            <Overview
              ref1={reference_1_image}
              ref2={reference_2_image}
              details={requestDetails}
              description={description}
            />
            <Divider />
            <MDButton onClick={handleOpen} variant="gradient" color="success" fullWidth>
              Set Price
            </MDButton>
            <SubmitForm
              id={id}
              discount={user.membership.discountRequestDesign}
              open={open}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          </Grid>
        )}
        {status === Status.WAITING_PAYMENT && (
          <Grid item xs={12}>
            <Divider />
            <Overview
              ref1={reference_1_image}
              ref2={reference_2_image}
              details={requestDetails}
              description={description}
            />
            <Divider />
            <Summary
              amount={amount}
              daysOfWork={daysOfWork}
              complexity={complexity}
              discount={discount}
            />
          </Grid>
        )}
        {status === Status.PAID && (
          <Grid item xs={12}>
            <Divider />
            <Overview
              ref1={reference_1_image}
              ref2={reference_2_image}
              details={requestDetails}
              description={description}
            />
            <Divider />
            <MDButton onClick={handleOpen} variant="gradient" color="success" fullWidth>
              Upload Result
            </MDButton>
            <UploadForm
              id={id}
              front={getFront()}
              back={getBack()}
              open={open}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          </Grid>
        )}
        {status === Status.EDITOR && (
          <Grid item xs={12}>
            <Divider />
            <Overview ref1={result_front_image} ref2={result_back_image} details={requestDetails} />
            <Divider />
            <MDButton onClick={handleOpen} variant="gradient" color="success" fullWidth>
              Open Design Editor
            </MDButton>
            <EditorForm
              id={id}
              details={requestDetails}
              status={Status.CONFIRM}
              open={open}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          </Grid>
        )}
        {status === Status.CONFIRM && (
          <Grid item xs={12}>
            <Divider />
            <Overview ref1={result_front_image} ref2={result_back_image} details={requestDetails} />
            <Divider />
          </Grid>
        )}
        {status === Status.CR && (
          <Grid item xs={12}>
            <Divider />
            {uploadedCR() ? (
              <Overview
                ref1={result_cr_front_image}
                ref2={result_cr_back_image}
                details={requestDetails}
                cr
              />
            ) : (
              <Overview
                ref1={reference_cr_1_image}
                ref2={reference_cr_2_image}
                details={requestDetails}
                description={description}
                cr
              />
            )}
            <Divider />
            <MDButton onClick={handleOpen} variant="gradient" color="success" fullWidth>
              {uploadedCR() ? "Open Design Editor" : "Upload Result"}
            </MDButton>
            {uploadedCR() ? (
              <EditorForm
                id={id}
                details={requestDetails}
                status={Status.ACCEPT}
                open={open}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                cr
              />
            ) : (
              <UploadForm
                id={id}
                front={getFront()}
                back={getBack()}
                open={open}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                cr
              />
            )}
          </Grid>
        )}
        {(status === Status.ACCEPT || status === Status.FINISHED) && (
          <Grid item xs={12}>
            <Divider />
            {uploadedCR() ? (
              <Overview
                ref1={result_cr_front_image}
                ref2={result_cr_back_image}
                details={requestDetails}
                cr
              />
            ) : (
              <Overview
                ref1={result_front_image}
                ref2={result_back_image}
                details={requestDetails}
              />
            )}

            <Divider />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}

export default RequestDetails;
