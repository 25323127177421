import React from "react";
import { Grid, Link, List, ListItem, ListItemText } from "@mui/material";
import PropTypes from "prop-types";
import { baseUrlApi } from "services/APIServices";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Overview({ ref1, ref2, details, description, cr }) {
  const Part = {
    FRONT: "Front",
    BACK: "Back",
    BOTH: "Both",
  };
  const variantColor = (item) => {
    const variant = item.category.variant.name;
    const category = item.category.name;
    const color = item.color.name;

    return `${variant} ${category} ${color}`;
  };

  const url = (type) => `${baseUrlApi}${type}`;
  const title = (name) => {
    const split = name.split("/");
    const result = split[split.length - 1].split("_");

    return `${result[1].toUpperCase()} ${result[2].toUpperCase()} ${
      cr ? result[3].toUpperCase() : ""
    }`;
  };
  return (
    <Grid container spacing={4}>
      {/* Left: Images */}
      <Grid item xs={12} md={4}>
        {ref1 && (
          <MDBox>
            <MDTypography variant="h6" gutterBottom>
              {title(ref1)}
            </MDTypography>
            <Link href={url(ref1)} target="_blank" rel="noopener noreferrer">
              <img
                src={url(ref1)}
                alt="Top"
                style={{
                  width: "200px",
                  height: "200px",
                  marginBottom: "16px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
            </Link>
          </MDBox>
        )}
        {ref2 && (
          <MDBox>
            <MDTypography variant="h6" gutterBottom>
              {title(ref2)}
            </MDTypography>
            <Link href={url(ref2)} target="_blank" rel="noopener noreferrer">
              <img
                src={url(ref2)}
                alt="Bottom"
                style={{ width: "200px", height: "200px", objectFit: "cover", cursor: "pointer" }}
              />
            </Link>
          </MDBox>
        )}
      </Grid>

      {/* Right: Description */}
      <Grid item xs={12} md={8}>
        {details.length > 0 && (
          <>
            <MDTypography variant="h6" gutterBottom>
              Request List
            </MDTypography>
            <List>
              {details.map((record) => (
                <ListItem
                  key={record.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <ListItemText
                    primary={variantColor(record)}
                    secondary={
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <MDTypography variant="body2">Part: {record.part}</MDTypography>
                        </Grid>
                        {(record.part === Part.FRONT || record.part === Part.BOTH) &&
                          record.frontImage && (
                            <Grid item xs={12} md={6}>
                              <Link
                                href={url(record.frontImage)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={url(record.frontImage)}
                                  alt="Bottom"
                                  style={{
                                    width: "200px",
                                    height: "200px",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                  }}
                                />
                              </Link>
                            </Grid>
                          )}
                        {(record.part === Part.BACK || record.part === Part.BOTH) &&
                          record.backImage && (
                            <Grid item xs={12} md={6}>
                              <Link
                                href={url(record.backImage)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={url(record.backImage)}
                                  alt="Bottom"
                                  style={{
                                    width: "200px",
                                    height: "200px",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                  }}
                                />
                              </Link>
                            </Grid>
                          )}
                      </Grid>
                    }
                    sx={{
                      textAlign: "left",
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
        {description && (
          <>
            <MDTypography sx={{ mt: 4 }} variant="h6" gutterBottom>
              Description{cr ? " CR" : ""}
            </MDTypography>
            <MDTypography variant="body1">{description}</MDTypography>
          </>
        )}
      </Grid>
    </Grid>
  );
}

Overview.defaultProps = {
  ref1: "",
  ref2: "",
  details: [],
  description: "",
  cr: false,
};

Overview.propTypes = {
  ref1: PropTypes.string,
  ref2: PropTypes.string,
  details: PropTypes.arrayOf(PropTypes.object),
  description: PropTypes.string,
  cr: PropTypes.bool,
};

export default Overview;
