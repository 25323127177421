/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Order Page: https://www.creative-tim.com/order/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
import { useEffect, useState, useMemo } from "react";
import { api } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

// @mui material components
import { Tooltip, IconButton } from "@mui/material";
import { ModeEdit } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DefaultCell from "utils/DefaultCell";
import StatusCell from "utils/StatusCell";
import CurrencyCell from "utils/CurrencyCell";
import DateTimeCell from "utils/DateTimeCell";

const URL = "/users";
const { getItem } = useLocalStorage();

const fetch = async (filter, search) => {
  const result = await api
    .get(
      `${URL}-panel?filter=${encodeURIComponent(JSON.stringify(filter))}${
        search ? `&search=${search}` : ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getItem("token")}`,
        },
      }
    )
    .then((res) => res.data);

  return result;
};

const dropdownRole = async () => {
  const filter = {
    order: "name asc",
    // where: {
    //   id: { nin: [1, 2] },
    // },
  };
  const result = await api
    .get(`/roles?filter=${encodeURIComponent(JSON.stringify(filter))}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getItem("token")}`,
      },
    })
    .then((res) => res.data);

  return result;
};

const dropdownMembership = async () => {
  const filter = {
    order: "id asc",
    // where: {
    //   id: { nin: [1, 2] },
    // },
  };
  const result = await api
    .get(`/memberships?filter=${encodeURIComponent(JSON.stringify(filter))}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);

  return result;
};

const post = async (action, param) => {
  if (action === "Add") {
    const result = await api
      .post(`/signup`, JSON.stringify(param.body), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => res.data);

    return result;
  }

  return false;
};

const patch = async (id, body) => {
  const result = await api
    .patch(`${URL}`, JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getItem("token")}`,
      },
    })
    .then((res) => res.data);

  return result;
};

const download = async (param) => {
  const result = await api
    .get(`${URL}-download?${param}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getItem("token")}`,
      },
    })
    .then((res) => res.data);

  return result;
};

const field = ({ setParamOpenDialog }) => [
  {
    Header: "fullname",
    accessor: "fullname",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "role",
    accessor: (row) => `${row.membership?.name ?? ""} ${row.role.name}`,
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "email",
    accessor: "email",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "phone",
    accessor: "phone",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "created date",
    accessor: "createdDt",
    Cell: ({ value }) => <DateTimeCell value={value} />,
  },
  {
    Header: "updated date",
    accessor: "updatedDt",
    Cell: ({ value }) => <DateTimeCell value={value} />,
  },
  {
    Header: "action",
    accessor: "action",
    width: "10%",
    Cell: ({ value }) => {
      let action = null;
      // const editedRoles = [1, 2];

      if (
        getItem("isOwner")
        //  && !editedRoles.includes(value.detail.roleId)
      ) {
        action = (
          <MDBox>
            <Tooltip title="Edit User">
              <IconButton color="info" onClick={() => setParamOpenDialog(value)}>
                <ModeEdit />
              </IconButton>
            </Tooltip>
          </MDBox>
        );
      }

      return action;
    },
  },
];

export { fetch, patch, download, field, dropdownRole, dropdownMembership, post };
