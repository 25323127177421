import { useState } from "react";
import CurrencyFormat from "react-currency-format";
import PropTypes from "prop-types";
import { Autocomplete, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { api } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

function SubmitForm({ id, discount = 0, open, handleClose, handleSubmit }) {
  const { getItem } = useLocalStorage();
  const complexityOpt = ["Select", "Easy", "Medium", "Hard"];

  const [formValues, setFormValues] = useState({
    amount: "",
    daysOfWork: "",
    complexity: complexityOpt[0],
    feedback: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const calculateDiscount = () => {
    const { amount } = formValues;
    const numericAmount = parseFloat(amount);
    return Number.isNaN(numericAmount) ? 0 : (numericAmount * discount) / 100;
  };

  const calculateTotal = () => {
    const { amount } = formValues;
    const numericAmount = parseFloat(amount);
    return Number.isNaN(numericAmount) ? 0 : numericAmount - calculateDiscount();
  };

  const validate = () => {
    const newErrors = {};
    if (!formValues.amount || Number.isNaN(formValues.amount)) {
      newErrors.amount = "Price must be a valid number.";
    } else if (Number(formValues.amount) === 0) {
      newErrors.amount = "Price must be greater than 0.";
    }

    if (!formValues.daysOfWork || Number.isNaN(formValues.daysOfWork)) {
      newErrors.daysOfWork = "Days of work must be a valid number.";
    } else if (Number(formValues.daysOfWork) === 0) {
      newErrors.daysOfWork = "Days of work must be greater than 0.";
    }

    if (formValues.complexity === "Select") {
      newErrors.complexity = "Complexity is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleConfirm = async () => {
    if (!validate()) return;

    const body = {
      amount: Number(formValues.amount),
      daysOfWork: Number(formValues.daysOfWork),
      complexity: formValues.complexity,
      feedback: formValues.feedback,
    };
    try {
      const result = await api
        .patch(`/request-design-order/${id}/set-transaction`, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getItem("token")}`,
          },
        })
        .then((res) => res.data);
      console.log("Response:", result);
      if (result.status) {
        handleClose();
        handleSubmit();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Modal open={open} onClose={(e) => e.stopPropagation()} disableEscapeKeyDown>
      <MDBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <MDTypography variant="h6" gutterBottom>
          Enter Details
        </MDTypography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CurrencyFormat
              value={formValues.amount}
              thousandSeparator="."
              decimalSeparator=","
              prefix="Rp"
              customInput={MDInput}
              onValueChange={(values) => {
                const { formattedValue, value } = values;

                handleChange("amount", value);
              }}
              autoFocus
              id="amount"
              label="Price"
              fullWidth
            />
            {errors.amount && (
              <MDTypography component="p" variant="button" color="error" sx={{ mt: 1 }}>
                {errors.amount}
              </MDTypography>
            )}
          </Grid>
          <Grid item xs={12}>
            <CurrencyFormat
              value={formValues.daysOfWork}
              thousandSeparator="."
              decimalSeparator=","
              suffix=" days"
              customInput={MDInput}
              onValueChange={(values) => {
                const { formattedValue, value } = values;

                handleChange("daysOfWork", value);
              }}
              id="daysOfWork"
              label="Days of Work"
              fullWidth
            />
            {errors.daysOfWork && (
              <MDTypography component="p" variant="button" color="error" sx={{ mt: 1 }}>
                {errors.daysOfWork}
              </MDTypography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="complexity"
              disableClearable
              value={formValues.complexity}
              options={complexityOpt}
              onChange={(event, newValue) => {
                handleChange("complexity", newValue);
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  label="Complexity"
                  error={!!errors.complexity}
                  helperText={errors.complexity}
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              id="feedback"
              label="Feedback"
              fullWidth
              multiline
              rows={4}
              value={formValues.feedback}
              onChange={(e) => {
                handleChange("feedback", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyFormat
              value={calculateDiscount()}
              thousandSeparator="."
              decimalSeparator=","
              prefix="Rp"
              customInput={MDInput}
              id="discount"
              label="Discount"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyFormat
              value={calculateTotal()}
              thousandSeparator="."
              decimalSeparator=","
              prefix="Rp"
              customInput={MDInput}
              id="total"
              label="Total"
              fullWidth
              disabled
            />
          </Grid>
        </Grid>
        <MDBox sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <MDButton variant="contained" color="error" onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </MDButton>
          <MDButton variant="contained" color="success" onClick={handleConfirm}>
            Confirm
          </MDButton>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

SubmitForm.propTypes = {
  id: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SubmitForm;
