/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Order Page: https://www.creative-tim.com/order/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
import { useEffect, useState, useMemo } from "react";
import { api } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

// @mui material components
import { Tooltip, IconButton } from "@mui/material";
import { ZoomIn } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DefaultCell from "utils/DefaultCell";
import StatusCell from "utils/StatusCell";
import CurrencyCell from "utils/CurrencyCell";
import DateTimeCell from "utils/DateTimeCell";

const URL = "/orders";
const { getItem } = useLocalStorage();

const fetch = async (filter, search) => {
  const result = await api
    .get(
      `${URL}?filter=${encodeURIComponent(JSON.stringify(filter))}${
        search ? `&search=${search}` : ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getItem("token")}`,
        },
      }
    )
    .then((res) => res.data);

  return result;
};

const biteship = async (id) => {
  const result = await api
    .get(`/order/${id}/biteship-order`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getItem("token")}`,
      },
    })
    .then((res) => res.data);

  return result;
};

const download = async (param) => {
  const result = await api
    .get(`${URL}-download?${param}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getItem("token")}`,
      },
    })
    .then((res) => res.data);

  return result;
};

const field = ({ setParamOpenDialog }) => [
  {
    Header: "invoice",
    accessor: "invoiceNumber",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "status",
    accessor: "status",
    Cell: ({ value }) => {
      let status;

      if (value === "Finished") {
        status = <StatusCell icon="done" color="success" status={value} />;
      } else if (value === "Process Packaging") {
        status = <StatusCell icon="info" color="info" status={value} />;
      } else if (value === "On Delivery") {
        status = <StatusCell icon="local_shipping" color="dark" status={value} />;
      } else if (value === "Waiting Payment") {
        status = <StatusCell icon="replay" color="warning" status={value} />;
      } else {
        status = <StatusCell icon="close" color="error" status={value} />;
      }

      return status;
    },
  },
  {
    Header: "delivery",
    accessor: "delivery",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} upper />,
  },
  {
    Header: "delivery status",
    accessor: "deliveryStatus",
    Cell: ({ value }) => <DefaultCell value={value ?? "-"} upper />,
  },
  {
    Header: "delivery fee",
    accessor: "deliveryFee",
    Cell: ({ value }) => {
      if (!value || value === 0) {
        return <DefaultCell value="-" />;
      }
      return <CurrencyCell value={value} />;
    },
  },
  {
    Header: "admin claim",
    accessor: "adminClaim",
    Cell: ({ value }) => {
      if (!value || value === 0) {
        return <DefaultCell value="-" />;
      }
      return <CurrencyCell value={value} />;
    },
  },
  {
    Header: "discount",
    accessor: "discount",
    Cell: ({ value }) => {
      if (!value || value === 0) {
        return <DefaultCell value="-" />;
      }
      return <CurrencyCell value={value} />;
    },
  },
  {
    Header: "total",
    accessor: "total",
    Cell: ({ value }) => <CurrencyCell value={value} />,
  },
  {
    Header: "created date",
    accessor: "createdDt",
    Cell: ({ value }) => <DateTimeCell value={value} />,
  },
  {
    Header: "updated date",
    accessor: "updatedDt",
    Cell: ({ value }) => <DateTimeCell value={value} />,
  },
  {
    Header: "action",
    accessor: "action",
    width: "10%",
    Cell: ({ value }) => (
      <MDBox>
        <Tooltip title="Detail">
          <IconButton color="info" onClick={() => setParamOpenDialog(value)}>
            <ZoomIn />
          </IconButton>
        </Tooltip>
      </MDBox>
    ),
  },
];

export { fetch, field, biteship, download };
