/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
import { useEffect, useState, useMemo } from "react";
import { api } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

// @mui material components
import { Tooltip, IconButton } from "@mui/material";
import { Done } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DefaultCell from "utils/DefaultCell";
import StatusCell from "utils/StatusCell";
import CurrencyCell from "utils/CurrencyCell";
import DateTimeCell from "utils/DateTimeCell";

const URL = "/withdrawals";
const { getItem } = useLocalStorage();

const fetch = async (filter, search) => {
  const result = await api
    .get(
      `${URL}-panel?filter=${encodeURIComponent(JSON.stringify(filter))}${
        search ? `&search=${search}` : ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getItem("token")}`,
        },
      }
    )
    .then((res) => res.data);

  return result;
};

const patch = async (id) => {
  const result = await api
    .patch(`${URL}/${id}`, null, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getItem("token")}`,
      },
    })
    .then((res) => res.data);

  return result;
};

const field = ({ setParamOpenDialog }) => [
  {
    Header: "request name",
    accessor: "user.bankAccount",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "account number",
    accessor: "user.bankNumber",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "bank",
    accessor: "user.bank.code",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "request amount",
    accessor: "total",
    Cell: ({ value }) => <CurrencyCell value={value} />,
  },
  {
    Header: "status",
    accessor: "status",
    Cell: ({ value }) => {
      let status;

      if (value === "Approved") {
        status = <StatusCell icon="done" color="success" status={value} />;
      } else {
        status = <StatusCell icon="replay" color="warning" status={value} />;
      }

      return status;
    },
  },
  {
    Header: "request date",
    accessor: "createdDt",
    Cell: ({ value }) => <DateTimeCell value={value} />,
  },
  {
    Header: "updated date",
    accessor: "updatedDt",
    Cell: ({ value }) => <DateTimeCell value={value} />,
  },
  {
    Header: "action",
    accessor: "action",
    width: "10%",
    Cell: ({ value }) => {
      let action;

      if (value.detail.status === "Waiting") {
        action = (
          <MDBox>
            <Tooltip title="Approve Request">
              <IconButton color="success" onClick={() => setParamOpenDialog(value)}>
                <Done />
              </IconButton>
            </Tooltip>
          </MDBox>
        );
      } else {
        action = null;
      }

      return action;
    },
  },
];

export { fetch, patch, field, URL };
