/* eslint-disable react/no-array-index-key */
import { useState } from "react";
import CurrencyFormat from "react-currency-format";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { Paper, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { api } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

function UploadForm({ id, front, back, open, handleClose, handleSubmit, cr }) {
  const { getItem } = useLocalStorage();

  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [filePreviews, setFilePreviews] = useState([]);
  const [filePreviews2, setFilePreviews2] = useState([]);
  const [summary, setSummary] = useState("");
  const [error, setError] = useState("");

  // Function to generate file previews (for images)
  const generatePreviews = (inputFiles, setPreviewState) => {
    const previews = inputFiles.map((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewState((prevPreviews) => [...prevPreviews, reader.result]);
      };
      reader.readAsDataURL(file);
      return reader.result;
    });
  };

  // Handle file drop and generate previews
  const handleFileDrop = (acceptedFiles, dropzoneNumber) => {
    setError("");
    if (dropzoneNumber === 1) {
      setFiles(acceptedFiles);
      setFilePreviews([]);
      generatePreviews(acceptedFiles, setFilePreviews);
    } else {
      setFiles2(acceptedFiles);
      setFilePreviews2([]);
      generatePreviews(acceptedFiles, setFilePreviews2);
    }
  };

  // Dropzone 1 (for the first file)
  const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
    onDrop: (acceptedFiles) => handleFileDrop(acceptedFiles, 1),
    multiple: false,
    noDrag: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  // Dropzone 2 (for the second file)
  const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
    onDrop: (acceptedFiles) => handleFileDrop(acceptedFiles, 2),
    multiple: false,
    noDrag: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const validate = () => {
    if (files.length === 0 && front) {
      setError("Result Front must be uploaded.");
      return false;
    }

    if (files2.length === 0 && back) {
      setError("Result Back must be uploaded.");
      return false;
    }

    if (!summary && !cr) {
      setError("Summary must be filled.");
      return false;
    }
    return true;
  };

  const handleConfirm = async () => {
    if (!validate()) return;

    const formData = new FormData();
    files.forEach((file) => formData.append("result_front", file));
    files2.forEach((file) => formData.append("result_back", file));

    if (!cr) {
      formData.append("summary", summary);
    }

    try {
      const result = await api
        .post(`/request-design-order/${id}/submit-result`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getItem("token")}`,
          },
        })
        .then((res) => res.data);
      console.log("Response:", result);
      if (result.status) {
        handleClose();
        handleSubmit();
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  return (
    <Modal open={open} onClose={(e) => e.stopPropagation()} disableEscapeKeyDown>
      <MDBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <MDTypography variant="h6" gutterBottom>
          Upload{cr ? " CR " : " "}Details
        </MDTypography>
        {front && (
          <MDBox
            sx={{
              border: "2px dashed #3f51b5",
              padding: 2,
              marginBottom: 2,
              textAlign: "center",
            }}
            {...getRootProps1()}
            style={{
              cursor: "pointer",
            }}
          >
            <input {...getInputProps1()} />
            <MDTypography>Upload your result{cr ? " cr " : " "}front, click here.</MDTypography>
          </MDBox>
        )}

        {filePreviews.length > 0 && (
          <Grid container spacing={2}>
            {filePreviews.map((preview, index) => (
              <Grid item key={index} xs={12}>
                <Paper
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 1,
                  }}
                >
                  <img
                    src={preview}
                    alt={`preview-${index}`}
                    style={{ maxWidth: "100%", maxHeight: 150, objectFit: "contain" }}
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}

        {back && (
          <MDBox
            sx={{
              border: "2px dashed #3f51b5",
              padding: 2,
              marginBottom: 2,
              textAlign: "center",
            }}
            {...getRootProps2()}
            style={{
              cursor: "pointer",
            }}
          >
            <input {...getInputProps2()} />
            <MDTypography>Upload your result{cr ? " cr " : " "}back, click here.</MDTypography>
          </MDBox>
        )}

        {filePreviews2.length > 0 && (
          <Grid container spacing={2}>
            {filePreviews2.map((preview, index) => (
              <Grid item key={index} xs={12}>
                <Paper
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 1,
                  }}
                >
                  <img
                    src={preview}
                    alt={`preview-${index}`}
                    style={{ maxWidth: "100%", maxHeight: 150, objectFit: "contain" }}
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}

        {!cr && (
          <MDBox sx={{ mt: 2 }}>
            <MDInput
              id="summary"
              label="Summary"
              fullWidth
              multiline
              rows={4}
              value={summary}
              onChange={(e) => {
                setError("");
                setSummary(e.target.value);
              }}
            />
          </MDBox>
        )}

        {error && (
          <MDTypography component="p" variant="button" color="error" sx={{ mt: 2 }}>
            {error}
          </MDTypography>
        )}
        <MDBox sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <MDButton variant="contained" color="error" onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </MDButton>
          <MDButton variant="contained" color="success" onClick={handleConfirm}>
            Confirm
          </MDButton>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

UploadForm.defaultProps = {
  cr: false,
};

UploadForm.propTypes = {
  id: PropTypes.number.isRequired,
  front: PropTypes.bool.isRequired,
  back: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cr: PropTypes.bool,
};

export default UploadForm;
