/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";
import CurrencyFormat from "react-currency-format";
import { Grid } from "@mui/material";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function Summary({ amount, daysOfWork, complexity, discount }) {
  const calculateTotal = () => amount + discount;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MDTypography variant="h6" fontWeight="medium">
          Summary
        </MDTypography>
      </Grid>

      <Grid item xs={6}>
        <MDTypography variant="body2" fontWeight="medium">
          Amount:
        </MDTypography>
      </Grid>
      <Grid item xs={6}>
        <MDTypography variant="body2" fontWeight="medium" align="right">
          <CurrencyFormat
            value={calculateTotal()}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix="Rp"
          />
        </MDTypography>
      </Grid>

      <Grid item xs={6}>
        <MDTypography variant="body2" fontWeight="medium">
          Days of Work:
        </MDTypography>
      </Grid>
      <Grid item xs={6}>
        <MDTypography variant="body2" fontWeight="medium" align="right">
          <CurrencyFormat
            value={daysOfWork}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            suffix=" Days"
          />
        </MDTypography>
      </Grid>

      <Grid item xs={6}>
        <MDTypography variant="body2" fontWeight="medium">
          Complexity:
        </MDTypography>
      </Grid>
      <Grid item xs={6}>
        <MDTypography variant="body2" fontWeight="medium" align="right">
          {complexity}
        </MDTypography>
      </Grid>

      <Grid item xs={6}>
        <MDTypography variant="body2" fontWeight="medium">
          Discount:
        </MDTypography>
      </Grid>
      <Grid item xs={6}>
        <MDTypography variant="body2" fontWeight="medium" align="right">
          {!discount ? (
            "-"
          ) : (
            <CurrencyFormat
              value={discount}
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              prefix="(-) Rp"
            />
          )}
        </MDTypography>
      </Grid>

      <Grid item xs={6}>
        <MDTypography variant="h6" fontWeight="medium">
          Total:
        </MDTypography>
      </Grid>
      <Grid item xs={6}>
        <MDTypography variant="h6" fontWeight="medium" align="right">
          <CurrencyFormat
            value={amount}
            displayType="text"
            thousandSeparator="."
            decimalSeparator=","
            prefix="Rp"
          />
        </MDTypography>
      </Grid>
    </Grid>
  );
}

Summary.propTypes = {
  amount: PropTypes.number.isRequired,
  daysOfWork: PropTypes.number.isRequired,
  complexity: PropTypes.string.isRequired,
  discount: PropTypes.number.isRequired,
};

export default Summary;
