/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Order Page: https://www.creative-tim.com/order/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useCallback, useEffect, useMemo } from "react";

// @mui material components
import {
  Autocomplete,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Stack,
} from "@mui/material";

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import DataTable from "utils/DataTable";

import RequestDetails from "utils/RequestDetails";
import FormField from "utils/FormField";
import ExcelExport from "utils/ExcelExport";

// Data
import { fetch, field } from "./data/tableData";

function DataOrder() {
  const statusOpt = [
    "All",
    "Pending",
    "Submit",
    "Waiting Payment",
    "Expired",
    "Paid",
    "Editor",
    "Confirm",
    "CR",
    "Decline",
    "Accept",
    "Finished",
  ];

  const complexityOpt = ["All", "Easy", "Medium", "Hard"];

  const [status, setStatus] = useState(statusOpt[0]);
  const [complexity, setComplexity] = useState(complexityOpt[0]);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [fetchTrigger, setFetchTrigger] = useState(0);

  const [open, setOpen] = useState(false);
  const [param, setParam] = useState({});

  const [openAction, setOpenAction] = useState(false);
  const [id, setId] = useState(null);

  const setParamOpenDialog = (value) => {
    setParam(value);
    setOpen(true);
  };

  const setIdOpenAction = (value) => {
    setId(value);
    setOpenAction(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAction = () => {
    setOpenAction(false);
  };

  const handleSubmit = () => {
    handleClose();
    setFetchTrigger((val) => val + 1);
  };

  const generateParam = (search) => {
    const paramPanel = "&panel=true";
    const paramSearch = `&search=${search ?? ""}`;
    const paramStatus = `&status=${status.replace("All", "")}`;
    const paramComplexity = `&complexity=${complexity.replace("All", "")}`;
    return paramPanel + paramSearch + paramStatus + paramComplexity;
  };

  const columns = useMemo(() => field({ setParamOpenDialog }), []);
  const fetchData = useCallback(
    async ({ pageIndex, pageSize, globalFilter }) => {
      setLoading(true);
      const skip = pageSize * pageIndex;
      const limit = skip + pageSize;
      const result = await fetch({ skip, limit }, generateParam(globalFilter));
      setData(result.data);
      setTotal(result.total);
      setPageCount(Math.ceil(result.total / pageSize));
      setLoading(false);
    },
    [status, complexity]
  );
  // useEffect(() => {
  //   console.log(param);
  // }, [param]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <MDBox lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Request Design Order Management
              </MDTypography>
              <MDTypography variant="button" color="text">
                List of All Request Design Order.
              </MDTypography>
            </MDBox>
            <MDBox ml="auto">
              <MDBox display="flex" alignItems="center" pt={1} pb={1}>
                <Autocomplete
                  id="status"
                  disableClearable
                  value={status}
                  options={statusOpt}
                  onChange={(event, newValue) => {
                    setStatus(newValue);
                  }}
                  size="medium"
                  sx={{ width: "12rem" }}
                  renderInput={(params) => <MDInput {...params} label="Status" />}
                />
                &nbsp;&nbsp;
                <Autocomplete
                  id="complexity"
                  disableClearable
                  value={complexity}
                  options={complexityOpt}
                  onChange={(event, newValue) => {
                    setComplexity(newValue);
                  }}
                  size="medium"
                  sx={{ width: "12rem" }}
                  renderInput={(params) => <MDInput {...params} label="Complexity" />}
                />
              </MDBox>
            </MDBox>
          </MDBox>
          <DataTable
            canSearch
            columns={columns}
            data={data}
            total={total}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            refreshTrigger={fetchTrigger}
          />
          <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
            <RequestDetails data={param} handleSubmit={handleSubmit} />
          </Dialog>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataOrder;
