/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Order Page: https://www.creative-tim.com/order/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
import { useEffect, useState, useMemo } from "react";
import { api } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

// @mui material components
import { Tooltip, IconButton } from "@mui/material";
import { ModeEdit } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DefaultCell from "utils/DefaultCell";
import StatusCell from "utils/StatusCell";
import CurrencyCell from "utils/CurrencyCell";
import DateTimeCell from "utils/DateTimeCell";

const URL = "/products-category";
const { getItem } = useLocalStorage();

const fetch = async (filter, search) => {
  const result = await api
    .get(
      `${URL}-panel?filter=${encodeURIComponent(JSON.stringify(filter))}${
        search ? `&search=${search}` : ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getItem("token")}`,
        },
      }
    )
    .then((res) => res.data);

  return result;
};

const patch = async (id, body) => {
  const result = await api
    .patch(`${URL}/${id}`, JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getItem("token")}`,
      },
    })
    .then((res) => res.data);

  return result;
};

const field = ({ setParamOpenDialog }) => [
  {
    Header: "variant",
    accessor: "variant.name",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "category",
    accessor: "name",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "minimum price",
    accessor: "minimumPrice",
    Cell: ({ value }) => <CurrencyCell value={value} />,
  },
  {
    Header: "weight",
    accessor: "weight",
    Cell: ({ value }) => <CurrencyCell value={value} isWeight />,
  },
  {
    Header: "status",
    accessor: "isActive",
    Cell: ({ value }) => {
      let status;

      if (value) {
        status = <StatusCell icon="done" color="success" status="Active" />;
      } else {
        status = <StatusCell icon="close" color="error" status="Inactive" />;
      }

      return status;
    },
  },
  {
    Header: "created date",
    accessor: "createdDt",
    Cell: ({ value }) => <DateTimeCell value={value} />,
  },
  {
    Header: "updated date",
    accessor: "updatedDt",
    Cell: ({ value }) => <DateTimeCell value={value} />,
  },
  {
    Header: "action",
    accessor: "action",
    width: "10%",
    Cell: ({ value }) => (
      <MDBox>
        <Tooltip title="Edit Product Category">
          <IconButton color="info" onClick={() => setParamOpenDialog(value)}>
            <ModeEdit />
          </IconButton>
        </Tooltip>
      </MDBox>
    ),
  },
];

export { fetch, patch, field };
