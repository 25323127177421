/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Order Page: https://www.creative-tim.com/order/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
import moment from "moment";
import { api } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

// @mui material components
import { Tooltip, IconButton } from "@mui/material";
import { ZoomIn } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DefaultCell from "utils/DefaultCell";
import StatusCell from "utils/StatusCell";
import CurrencyCell from "utils/CurrencyCell";
import DateTimeCell from "utils/DateTimeCell";

const URL = "/request-design-order-list";
const { getItem } = useLocalStorage();

const fetch = async (filter, search) => {
  const result = await api
    .get(`${URL}?filter=${encodeURIComponent(JSON.stringify(filter))}${search}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getItem("token")}`,
      },
    })
    .then((res) => res.data);

  return result;
};

const field = ({ setParamOpenDialog }) => [
  {
    Header: "Fullname",
    accessor: "user.fullname",
    Cell: ({ value }) => <DefaultCell value={value ?? "-"} />,
  },
  {
    Header: "Membership",
    accessor: "user.membership.name",
    Cell: ({ value }) => <DefaultCell value={value ?? "-"} />,
  },
  {
    Header: "invoice",
    accessor: "invoiceNumber",
    Cell: ({ value }) => <DefaultCell value={value ?? "-"} />,
  },
  {
    Header: "status",
    accessor: "status",
    Cell: ({ value }) => {
      let status;

      switch (value) {
        case "Finished":
          status = <StatusCell icon="done" color="success" status={value} />;
          break;
        case "Expired":
          status = <StatusCell icon="close" color="error" status={value} />;
          break;
        case "Decline":
          status = <StatusCell icon="close" color="error" status={value} />;
          break;
        case "Pending":
          status = <StatusCell icon="replay" color="warning" status={value} />;
          break;
        case "Waiting Payment":
          status = <StatusCell icon="replay" color="warning" status={value} />;
          break;
        default:
          status = <StatusCell icon="info" color="info" status={value} />;
          break;
      }

      return status;
    },
  },
  {
    Header: "Complexity",
    accessor: "complexity",
    Cell: ({ value }) => <DefaultCell value={value ?? "-"} upper />,
  },
  {
    Header: "Price",
    accessor: "amount",
    Cell: ({ value }) => {
      if (!value || value === 0) {
        return <DefaultCell value="-" />;
      }
      return <CurrencyCell value={value} />;
    },
  },
  {
    Header: "discount",
    accessor: "discount",
    Cell: ({ value }) => {
      if (!value || value === 0) {
        return <DefaultCell value="-" />;
      }
      return <CurrencyCell value={value} />;
    },
  },
  // {
  //   Header: "total",
  //   accessor: "total",
  //   Cell: ({ value }) => <CurrencyCell value={value} />,
  // },
  {
    Header: "Due Date",
    accessor: "dueDate",
    Cell: ({ value }) => <DateTimeCell value={value} date />,
  },
  {
    Header: "Updated Date",
    accessor: "updatedDt",
    Cell: ({ value }) => <DateTimeCell value={value} />,
  },
  {
    Header: "action",
    width: "10%",
    Cell: ({ row }) => (
      <MDBox>
        <Tooltip title="Detail">
          <IconButton color="info" onClick={() => setParamOpenDialog(row.original)}>
            <ZoomIn />
          </IconButton>
        </Tooltip>
      </MDBox>
    ),
  },
];

export { fetch, field };
