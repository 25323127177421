import axios from "axios";
import logo from "assets/images/logo-icon.png";

// const baseUrl = "http://localhost:8000/createe-frontend";
// const baseUrl = "http://localhost/createe-frontend";
const baseUrl = "https://ccreatee.com";

// const baseUrlApi = "http://localhost:3001";
// const baseUrlApi = window.location.protocol + "//" + window.location.hostname + ":3001";
// const baseUrlApi = `https://apicreatee.verztec.co.id`;
const baseUrlApi = `https://api.ccreatee.com`;

const getFile = (src) => (src ? `${baseUrlApi}${src}` : logo);
const api = axios.create({
  baseURL: baseUrlApi,
});

const getEditorUrl = (item) =>
  `${baseUrl}/pages/editor_product?variant=${item.category.variantId}&category=${item.categoryId}&mode=1&orderId=${item.requestId}`;

export { api, getFile, baseUrlApi, baseUrl, getEditorUrl };
