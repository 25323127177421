/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Order Page: https://www.creative-tim.com/order/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable react/prop-types */
import { useEffect, useState, useMemo } from "react";
import { api } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

// @mui material components
import { Tooltip, IconButton } from "@mui/material";
import { ModeEdit } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DefaultCell from "utils/DefaultCell";
import StatusCell from "utils/StatusCell";
import CurrencyCell from "utils/CurrencyCell";
import DateTimeCell from "utils/DateTimeCell";

const URL = "/memberships";
const { getItem } = useLocalStorage();

const fetch = async (filter, search) => {
  const result = await api
    .get(
      `${URL}-panel?filter=${encodeURIComponent(JSON.stringify(filter))}${
        search ? `&search=${search}` : ""
      }`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getItem("token")}`,
        },
      }
    )
    .then((res) => res.data);

  return result;
};

const patch = async (id, body) => {
  const result = await api
    .patch(`${URL}/${id}`, JSON.stringify(body), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getItem("token")}`,
      },
    })
    .then((res) => res.data);

  return result;
};

const field = ({ setParamOpenDialog }) => [
  {
    Header: "name",
    accessor: "name",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "description",
    accessor: "description",
    Cell: ({ value }) => <DefaultCell value={value ?? ""} />,
  },
  {
    Header: "pricing",
    accessor: "pricing",
    Cell: ({ value }) => <CurrencyCell value={value} />,
  },
  {
    Header: "status",
    accessor: "isActive",
    Cell: ({ value }) => {
      let status;

      if (value) {
        status = <StatusCell icon="done" color="success" status="Active" />;
      } else {
        status = <StatusCell icon="close" color="error" status="Inactive" />;
      }

      return status;
    },
  },
  {
    Header: "created date",
    accessor: "createdDt",
    Cell: ({ value }) => <DateTimeCell value={value} />,
  },
  {
    Header: "updated date",
    accessor: "updatedDt",
    Cell: ({ value }) => <DateTimeCell value={value} />,
  },
  {
    Header: "action",
    accessor: "action",
    width: "10%",
    Cell: ({ value }) => {
      let action = null;
      const notAllow = [1];

      if (getItem("isOwner") && !notAllow.includes(value.id)) {
        action = (
          <MDBox>
            <Tooltip title="Edit Membership">
              <IconButton color="info" onClick={() => setParamOpenDialog(value)}>
                <ModeEdit />
              </IconButton>
            </Tooltip>
          </MDBox>
        );
      }

      return action;
    },
  },
];

export { fetch, patch, field };
