/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import moment from "moment";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";

function DateTimeCell({ value, date }) {
  const toDate = () => {
    const now = moment();
    const newDate = moment(value.split(".")[0]);

    return date ? newDate.format("YYYY-MM-DD") : newDate.format("YYYY-MM-DD HH:mm:ss");
  };
  return (
    <MDTypography variant="caption" fontWeight="medium" color="text">
      {value ? toDate() : "-"}
    </MDTypography>
  );
}

// Setting default values for the props of DateTimeCell
DateTimeCell.defaultProps = {
  date: false,
};

// Typechecking props for the DateTimeCell
DateTimeCell.propTypes = {
  value: PropTypes.string.isRequired,
  date: PropTypes.bool,
};

export default DateTimeCell;
