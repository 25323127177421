/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
} from "@mui/material";
import { OpenInNew, Refresh } from "@mui/icons-material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { api, getEditorUrl } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";

function EditorForm({ id, details, status, open, handleClose, handleSubmit, cr }) {
  const { getItem } = useLocalStorage();

  const [items, setItems] = useState(details);
  const [error, setError] = useState("");

  const variantColor = (item) => {
    const variant = item.category.variant.name;
    const category = item.category.name;
    const color = item.color.name;

    return `${variant} ${category} ${color}`;
  };
  const isDone = (item) => item.frontImage || item.backImage;
  const refreshStatus = async () => {
    setError("");
    try {
      const result = await api
        .get(`/request-design-order/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getItem("token")}`,
          },
        })
        .then((res) => res.data);

      setItems(result.data.requestDetails);
      console.log("Response:", result);
      // if (result.status) {
      //   handleClose();
      //   handleSubmit();
      // }
    } catch (err) {
      console.error("Error:", err);
    }
  };
  const validate = () => {
    const doneAll = items.every((item) => item.frontImage || item.backImage);
    if (!doneAll) setError("Please Complete All Task Editor.");

    return doneAll;
  };
  const handleConfirm = async () => {
    if (!validate()) return;

    const body = {
      status,
    };

    try {
      const result = await api
        .patch(`/request-design-order/${id}/set-transaction`, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getItem("token")}`,
          },
        })
        .then((res) => res.data);
      console.log("Response:", result);
      if (result.status) {
        handleClose();
        handleSubmit();
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };
  return (
    <Modal open={open} onClose={(e) => e.stopPropagation()} disableEscapeKeyDown>
      <MDBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <MDTypography variant="h6" gutterBottom>
          Task{cr ? " CR " : " "}Editor
        </MDTypography>
        <List>
          {items.map((item) => (
            <ListItem key={item.id}>
              <ListItemText
                primary={variantColor(item)}
                secondary={
                  <MDBox>
                    <MDTypography variant="body2">Part: {item.part}</MDTypography>
                    <MDTypography variant="body2" color={isDone(item) ? "success" : "error"}>
                      Status: {isDone(item) ? "Done" : "Not Yet"}
                    </MDTypography>
                  </MDBox>
                }
              />
              <ListItemSecondaryAction>
                <Tooltip title="Open in a new tab">
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={() => window.open(getEditorUrl(item), "_blank")}
                    disabled={isDone(item)}
                  >
                    <OpenInNew />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Refresh status">
                  <IconButton
                    edge="end"
                    color="secondary"
                    onClick={() => refreshStatus(item.id)}
                    disabled={isDone(item)}
                  >
                    <Refresh />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        {error && (
          <MDTypography component="p" variant="button" color="error" sx={{ mt: 1 }}>
            {error}
          </MDTypography>
        )}
        <MDBox sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <MDButton variant="contained" color="error" onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </MDButton>
          <MDButton variant="contained" color="success" onClick={handleConfirm}>
            Confirm
          </MDButton>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

EditorForm.defaultProps = {
  cr: false,
};

EditorForm.propTypes = {
  id: PropTypes.number.isRequired,
  details: PropTypes.arrayOf(PropTypes.object).isRequired,
  status: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cr: PropTypes.bool,
};

export default EditorForm;
