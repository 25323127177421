/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useCallback, useEffect, useMemo } from "react";

import CurrencyFormat from "react-currency-format";

// @mui material components
import {
  Card,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
} from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDropzone from "components/MDDropzone";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "utils/DataTable";

// ProductPage page components
import ProductImages from "layouts/applications/data-product/components/ProductImages";
import ProductInfo from "layouts/applications/data-product/components/ProductInfo";

import { fetch, post, field, URL } from "layouts/applications/data-product/data/tableData";
import { baseUrlApi, getFile } from "services/APIServices";
import useLocalStorage from "services/useLocalstorage";
import { v4 as uuidv4 } from "uuid";

function DataProduct() {
  const { getItem } = useLocalStorage();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [fetchTrigger, setFetchTrigger] = useState(0);

  const [open, setOpen] = useState(false);
  const [param, setParam] = useState({});

  const [openDetail, setOpenDetail] = useState(false);
  // const [productDetail, setProductDetail] = useState({});
  // const [trigger, setTrigger] = useState(0);
  const [action, setAction] = useState("");
  const [reason, setReason] = useState("");
  const [fileName, setFileName] = useState("");
  const [price, setPrice] = useState("");

  const setParamOpenDialog = (value) => {
    setParam(value);
    setPrice(value.detail.price);
    setOpenDetail(true);
  };

  const setActionOpenDialog = (value) => {
    setAction(value);
    setOpen(true);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    if (action === "reject") {
      const result = await post(param.id, { action, reason });
    }

    if (action === "price") {
      const result = await post(param.id, { action, price: parseInt(price, 10) });
    }

    if (action === "delete") {
      const result = await post(param.id, { action });
    }
    handleCloseDetail();
    handleClose();
    setFetchTrigger((val) => val + 1);
  };

  const handleConfirmState = () => {
    switch (action) {
      case "model":
        return !fileName;
      case "reject":
        return !reason;
      case "price":
        return !price;
      default:
        return false;
    }
  };

  const columns = useMemo(() => field({ setParamOpenDialog }), []);
  const fetchData = useCallback(async ({ pageIndex, pageSize, globalFilter }) => {
    setLoading(true);

    const skip = pageSize * pageIndex;
    const limit = skip + pageSize;
    const result = await fetch({ skip, limit }, globalFilter);
    setData(
      result.data.map((el) => ({
        ...el,
        flow: {
          val: el.flow,
          key: el.price,
        },
        action: {
          id: el.id,
          detail: {
            name: el.name,
            price: el.price,
            brand: el.brand.name ?? el.brand.email,
            logo: getFile(el.brand.logoPath ?? ""),
            category: el.availableSizes ? el.availableSizes[0].size.category.name : "",
            creator: el.privacy === "Private" ? "CreaTee Custom Design" : el.brand.name,
            variant: el.availableSizes ? el.availableSizes[0].size.category.variant.name : "",
            description: el.description,
            privacy: el.privacy,
            flow: el.flow,
            size: el.availableSizes ? el.availableSizes.map((e) => e.size.name).join(", ") : "",
            color: el.availableColors.map((e) => e.color.name).join(", "),
          },
          images: [
            ...el.availableColors.map((e) => ({
              src: getFile(e.frontImage ?? ""),
              key: uuidv4(),
            })),
            ...el.availableColors.map((e) => ({ src: getFile(e.backImage ?? ""), key: uuidv4() })),
            // ...[{ src: getFile(el.imageModel ?? ""), key: uuidv4() }],
          ],
        },
      }))
    );
    setTotal(result.total);
    setPageCount(Math.ceil(result.total / pageSize));
    setLoading(false);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Product Management
            </MDTypography>
            <MDTypography variant="button" color="text">
              List of All Product.
            </MDTypography>
          </MDBox>
          <DataTable
            canSearch
            columns={columns}
            data={data}
            total={total}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            refreshTrigger={fetchTrigger}
          />
        </Card>
        <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
          <DialogTitle>
            {action === "model"
              ? "Upload Model"
              : `${action.charAt(0).toUpperCase()}${action.slice(1)}`}{" "}
            Product
          </DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={3}>
              <MDBox pt={1} pb={1}>
                <DialogContentText>Apakah Anda yakin melakukan proses ini?</DialogContentText>
              </MDBox>
              {action === "model" && (
                <MDDropzone
                  options={{
                    url: `${baseUrlApi}${URL}/${param.id}/model`,
                    headers: { Authorization: `Bearer ${getItem("token")}` },
                    acceptedFiles: "image/jpeg,image/png",
                    success(file, response) {
                      setFileName(file.name);
                    },
                  }}
                />
              )}
              {action === "reject" && (
                <MDBox pt={1} pb={1}>
                  <MDInput
                    autoFocus
                    id="reason"
                    label="Reason Field"
                    fullWidth
                    multiline
                    rows={4}
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                  />
                </MDBox>
              )}
              {action === "price" && (
                <MDBox pt={1} pb={1}>
                  <CurrencyFormat
                    value={price}
                    thousandSeparator="."
                    decimalSeparator=","
                    prefix="Rp"
                    customInput={MDInput}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      // formattedValue = $2,223
                      // value ie, 2223
                      setPrice(value);
                    }}
                    autoFocus
                    id="price"
                    label="Price"
                    fullWidth
                  />
                </MDBox>
              )}
            </Stack>
            <DialogActions>
              <MDButton variant="text" color="error" onClick={handleClose}>
                Cancel
              </MDButton>
              <MDButton
                variant="text"
                color="info"
                disabled={handleConfirmState()}
                onClick={handleSave}
              >
                Confirm
              </MDButton>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Dialog fullWidth maxWidth="xl" open={openDetail} onClose={handleCloseDetail}>
          <DialogTitle>Product Details</DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} xl={5}>
                <ProductImages data={param.images ?? []} />
              </Grid>
              <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                <ProductInfo data={param.detail ?? {}} action={setActionOpenDialog} />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataProduct;
